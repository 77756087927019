import * as React from "react";
import {FC, useEffect, useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
// @ts-ignore
import * as style from './index.module.scss';
// @ts-ignore
import * as mainStyle from '../../theme/main.module.scss';
import {Client, handleApiError} from "../../services/ApiService";
import {setKey} from "../../stores/auth/authSlice";
import {setBasketAmount, setBasketID} from "../../stores/basket/basketSlice";
import {setMember, setMemberID, setVendorID} from "../../stores/member/memberSlice";
import {connect, ConnectedProps} from "react-redux";
import Layout from "../../components/Navigation/layout";
import {toast} from "react-toastify";
import yup from "@raisin/yup-validations";
import * as yup2 from "yup";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentSetupForm from "./PaymentSetupForm";


const mapState = ({auth, member, basket}) => ({
    auth,
    basket,
    member,
})

const mapDispatch = {
    setKey,
    setBasketID,
    setMember,
    setMemberID,
    setVendorID,
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    Name: string,
    Email: string,
    IBAN: string,
    WithdrawCheck: boolean
}

const schema = yup.object().shape({
    Name: yup.string().required('Bitte gib einen Namen an.'),
    Email: yup.string().required('Bitte gib eine E-Mail Adresse an.').email('Die angegebene E-Mail Adresse ist ungültig'),
    IBAN: yup.string().iban().required('Bitte gib einen gültigen IBAN an.'),
})

const secondSchema = yup2.object().shape({
    WithdrawCheck: yup2.boolean().required().isTrue('Bitte bestätige, dass du einverstanden bist.'),
})

const VendorApproved: ({
                           auth,
                           basket,
                           member,
                           setKey,
                           setBasketID,
                           setMember,
                           setMemberID,
                           setVendorID,
                           setBasketAmount
                       }: { auth: any; basket: any; member: any; setKey: any; setBasketID: any; setMember: any; setMemberID: any; setVendorID: any; setBasketAmount: any }) => JSX.Element = (
    {
        auth,
        basket,
        member,
        setKey,
        setBasketID,
        setMember,
        setMemberID,
        setVendorID,
        setBasketAmount
    }
) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const {register: registerTwo, handleSubmit: handleSubmitTwo, formState: {errors: errorsTwo}} = useForm<FormInputs>({
        resolver: yupResolver(secondSchema)
    });

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    useEffect(() => {
        const URLparams = new URLSearchParams(location.search);
        const hash = URLparams.get("hash");
        if (hash) {
            login(hash)
        }
    }, [])

    useEffect(() => {
        if (error.length) {
            // toast.error(error);
        }
    }, [error])

    function login(hash: string) {
        Client.Raw.post(
            'Member/oneTimeAuthorize',
            {
                'Hash': hash
            }
        ).then((response) => {
            if (response.data.valid === false) {
                setLoading(false);
                setError(response.data[0].messages)
            } else {
                setKey(response.data.Key);
                setMemberID(response.data.MemberID);
                Client.Raw.get('Member/' + response.data.MemberID).then((res) => {
                    setVendorID(res.data.VendorID);
                    setMember(res.data);
                }).catch(handleApiError)
                Client.Basket.fetchAuthorized(response.data.Key, basket.BasketID ? basket.BasketID : '').then((res) => {
                    setBasketID(res.data.ID);
                    setBasketAmount(res.data.TotalItems);
                }).catch(handleApiError);
            }
        }).catch(handleApiError)
    }

    function onSubmit(data) {
        Client.Auth.authPostWithAction(auth.Key, 'Member', member.MemberID, 'updateIBAN/', {
            MemberID: member.MemberID,
            IBAN: data.IBAN,
            Email: data.Email,
            Name: data.Name
        }).then((res) => {
            if (res.data === true) {
                window.open(Client.baseURL + 'Vendor/onBoardingDirect/' + member.Member.StripeAccountID, '_self').focus();
            } else {

            }
        }).catch(handleApiError)
    }

    return (
        <>
            <Layout>
                <div className={style.logoutHolder}>
                    <div className={"d-flex flex-column justify-content-center align-items-center container"}>
                        <h2 className={style.headingForm}>Bankverbindung</h2>
                        <Elements stripe={stripePromise}>
                            <PaymentSetupForm/>
                        </Elements>
                    </div>
                </div>
            </Layout>
        </>
    )
}

// @ts-ignore
export default connector(VendorApproved);
